<template lang="pug">
div
  .d-flex.flex-row.justify-end.mb-3
    IconBtn(@click="edit()", title="Add", icon="mdi-plus")
  v-card(flat)
    simple-table
      thead
        tr
          th Name
          th Time
          th Courses
          th Price
          th Limit
          th 
      tbody
        tr(
          v-for="(item, index) in vouchers",
          :key="index",
          @click="edit(item)",
          role="button"
        )
          td {{ item.name }}
          td
            div(v-if="item.selectedTime")
              span {{ item.date | day(true) }}
              br
              span {{ item.start | minutesToTime }} - {{ item.end | minutesToTime }}
            span(v-else) Anytime
          td(style="white-space: normal")
            span(v-if="item.courses.length > 0") {{ getCourseNames(item.courses) }}
            span(v-else) Any
          td
            span(v-if="item.isPct") -{{ item.pct }}%
            span(v-else) {{ -item.amount | currency }}
          td {{ item.limit == 0 ? "Any" : item.limit }}
          td.text-right
            v-btn(icon, small, @click.stop="handleRemove(item)")
              v-icon(small, color="error") mdi-delete
  Form(ref="form")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  computed: {
    ...mapGetters(["biz", "vouchers"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setVouchers", "removeVoucher"]),
    async load() {
      if (!this.biz || !this.biz._id) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.voucher.list(params);
      this.setVouchers(result);
    },
    getCourseNames(ids) {
      if (!ids || !this.biz) return "";
      let names = [];
      _.each(ids, (id) => {
        const course = this.biz.courses?.find((o) => o._id === id);
        if (course) names.push(course.name);
      });
      return names.sort().join(", ");
    },
    edit(item) {
      this.$refs.form.open(item);
    },
    handleRemove(item) {
      const message = "Do you want to remove this voucher?";
      confirm(message) && this.remove(item);
    },
    async remove(item) {
      if (!item || !item._id) return;
      const options = { criteria: { _id: item._id } };
      await this.$api.voucher.delete(options);
      this.removeVoucher(item);
    },
  },
};
</script>
