<template lang="pug">
div(v-if="content")
  v-subheader System Methods
  .px-3
    v-row(dense)
      SystemMethod(
        title="Credit Card",
        detail="PAX Terminal",
        v-model="content.creditcard"
      )
      SystemMethod(
        title="Debit Card",
        detail="for bypass the surcharge",
        v-model="content.debit"
      )
      SystemMethod(
        title="Stripe",
        detail="Stripe Terminals",
        v-model="content.stripe"
      )
      SystemMethod(
        title="External Card",
        detail="Other Terminals",
        v-model="content.excard"
      )
      SystemMethod(title="Check", v-model="content.check")
      SystemMethod(
        title="Gift Certificate",
        detail="Paper Certificates",
        v-model="content.gift_cert"
      )
  v-subheader Custom Methods
  .px-3
    v-row(dense)
      CustomMethod(
        v-for="(method, index) in content.custom",
        :key="method.name",
        :method="method",
        @remove="deleteCustomMethod",
        @edit="editCustomMethod($event, index)"
      )
      AddMethod(@add="addCustomMethod", :custom="content.custom")
  v-list.pb-0(dense)
    v-list-item
      v-list-item-content
        v-list-item-title Max amount on card payment
        v-list-item-subtitle
          span.caption Available POS 6.44+
      v-list-item-action
        PriceField(
          label="Max Amount",
          v-model="content.maxCard",
          style="width: 150px",
          :min="0",
          :step="100",
          hide-details
        )
    v-list-item
      v-list-item-content
        v-list-item-title Manager Approval for void after payment
        v-list-item-subtitle
          span.caption Available POS 6.47+
      v-list-item-action
        v-select(
          style="width: 150px",
          v-model="content.voidTimeLimit",
          :items="timeLimitOptions",
          hide-details
        )
  v-divider
</template>

<script>
import AddMethod from "./AddMethod.vue";
import CustomMethod from "./CustomMethod.vue";
import SystemMethod from "./SystemMethod.vue";
import { validateMethod } from "./utils";

export default {
  components: { SystemMethod, AddMethod, CustomMethod },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      content: this.value,
      // 0-5 minutes
      timeLimitOptions: [
        { text: "Immediately", value: 0 },
        { text: "after 1 minute", value: 1 },
        { text: "after 2 minutes", value: 2 },
        { text: "after 3 minutes", value: 3 },
        { text: "after 4 minutes", value: 4 },
        { text: "after 5 minutes", value: 5 },
      ],
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.content = this.value;
        this.content.maxCard = this.content.maxCard || 5000;
        this.content.maxCard = parseFloat(this.content.maxCard);
      },
    },
    content: {
      deep: true,
      handler(oldVal, newVal) {
        if (oldVal == newVal) return;
        this.$emit("input", this.content);
      },
    },
  },
  methods: {
    // method: {name, desc}
    addCustomMethod(method) {
      this.content.custom = [...(this.content.custom || []), method];
    },
    deleteCustomMethod(method) {
      this.content.custom = this.content.custom.filter(
        (m) => m.name != method.name
      );
    },
    editCustomMethod(method, index) {
      try {
        validateMethod(method.name);
        this.content.custom[index].name = method.name;
        this.content.custom[index].desc = method.desc;
        this.content.custom = [...this.content.custom];
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
  },
};
</script>
