<template lang="pug">
v-dialog(v-model="dialog", max-width="550", @keydown.esc="dialog = false")
  v-card
    v-toolbar(dense, flat)
      .overline Edit Voucher
    v-form(@submit.prevent="submit", v-if="voucher")
      v-card-text
        v-text-field(label="Name", v-model="voucher.name", hide-details)
        v-row
          v-col
            v-switch(
              color="secondary",
              v-model="voucher.isPct",
              label="Percentage Discount"
            )
          v-col
            div(v-if="voucher.isPct")
              v-text-field(
                type="number",
                v-model="voucher.pct",
                prefix="-",
                suffix="%",
                label="Percentage Discount",
                outline,
                hide-details
              )
            div(v-else)
              v-text-field(
                type="number",
                v-model="voucher.amount",
                prefix="-$",
                label="Amount Discount",
                outline,
                hide-details
              )
        v-switch(
          color="secondary",
          v-model="voucher.selectedTime",
          label="Limited Time"
        )
        div(v-if="voucher.selectedTime")
          .body-2 Date/Time
          DaysPicker(v-model="voucher.date")
          v-row
            v-col
              TimeSelector(v-model="voucher.start", title="Start")
            v-col
              TimeSelector(v-model="voucher.end", title="End")
        v-select(
          label="Applicable courses",
          v-model="voucher.courses",
          multiple,
          :items="courses",
          small-chips,
          deletable-chips
        )
        v-select(
          label="Limit",
          v-model="voucher.limit",
          :items="limitItems",
          hide-details
        )
      .pl-2.red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      voucher: null,
      // 1-3
      limitItems: [
        { text: "No Limit", value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
      ],
      dialog: false,
      errors: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    endItems() {
      if (!this.voucher || !this.voucher.start) return this.items;
      const minutesToTime = this.$options.filters.minutesToTime;
      return _.map(_.range(this.voucher.start, 1440, 30), (o) => {
        return {
          value: o,
          text: minutesToTime(o),
        };
      });
    },
    courses() {
      return this.biz?.courses?.map((o) => {
        return { text: o.name, value: o._id };
      });
    },
  },
  methods: {
    ...mapActions(["addVoucher", "updateVoucher"]),
    open(data) {
      if (data) {
        this.voucher = JSON.parse(JSON.stringify(data));
      } else {
        this.voucher = {
          biz: this.biz._id,
          name: "",
          amount: 0,
          isPct: false,
          pct: 0,
          selectedTime: false,
          date: [],
          start: 480,
          end: 1200,
          courses: [],
        };
      }
      this.errors = [];
      this.dialog = true;
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.voucher.name || this.voucher.name.length === 0)
        this.errors.push("Name is required");

      if (this.voucher.selectedTime) {
        if (!this.voucher.date || this.voucher.date.length === 0) {
          this.errors.push("Select Date");
        }
        if (this.voucher.start < 0 || this.voucher.end <= this.voucher.start) {
          this.errors.push("Invalid start/end time");
        }
      }

      if (!this.voucher.isPct && this.voucher.amount <= 0) {
        this.errors.push("invalid Amount Discount");
      } else if (
        this.voucher.isPct &&
        (this.voucher.pct <= 0 || this.voucher.pct > 100)
      ) {
        this.errors.push("Percentage Discount should be between 0 and 100");
      }
      if (this.errors.length > 0) return;

      this.loading = true;
      try {
        if (this.voucher?._id) {
          const params = {
            criteria: { _id: this.voucher._id },
            action: { $set: this.voucher },
          };
          const result = await this.$api.voucher.update(params);
          this.updateVoucher(result);
          this.dialog = false;
        } else {
          const result = await this.$api.voucher.create(this.voucher);
          this.addVoucher(result);
          this.dialog = false;
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
