<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import _ from "underscore";
import CSV from "/libs/utils/CSV";

export default {
  props: {
    persons: { type: Array, default: () => [] },
    accessible: { type: Boolean, default: false },
  },
  methods: {
    download() {
      if (!this.accessible) {
        this.$toast.error("You don't have permission to download");
        return;
      }
      const filters = this.$options.filters;
      const csv = new CSV();
      const header = [
        "First Name",
        "Last Name",
        "Receipt Display Name",
        "Employee ID",
        "Phone",
        "Email",
        "DOB",
        "Passcode",
        "Role",
        "Wage",
      ];
      csv.addRow(header);
      _.chain(this.persons)
        .sortBy("status")
        .sortBy("last_name")
        .each((item) => {
          const first_name = item.first_name || "";
          const last_name = item.last_name || "";
          const name = item.name || "";
          const employeeID = item.employeeID || "";
          const phone = item.phone || "";
          const email = item.email || "";
          const dob = item.dob || "";
          const pass = String(item.pass) || "";
          let row = [
            first_name,
            last_name,
            name,
            employeeID,
            phone,
            email,
            dob,
            pass,
          ];
          // put roles and wages in the same row one by one
          item.roles.forEach((o) => {
            row.push(o.name);
            row.push(filters.currency(o.rate));
          });
          csv.addRow(row);
        });
      csv.save("employees");
    },
  },
};
</script>